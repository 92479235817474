<template>
  <v-container class="pa-0" v-if="!isEmptyObject(selected)">
    <v-list v-if="selected.length" class="py-1">
      <v-list-group
        v-for="(item, index) in selected"
        :key="item.title"
        no-action
        v-model="item.active"
      >
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title
              :class="{ 'font-weight-medium': selected[index].model.length }"
            >
              {{ item?.title || '' }} ({{ selected[index].model.length }})
            </v-list-item-title>
          </v-list-item-content>
        </template>

        <v-list-item-group
          @change="doChange"
          multiple
          active-class="no-active"
          no-action
          v-model="selected[index].model"
        >
          <template v-for="subitem in item.items">
            <v-list-item :key="subitem.field" :value="subitem.field">
              <template v-slot:default="{ active }">
                <v-list-item-content>
                  <v-list-item-subtitle>{{
                    subitem.title
                  }}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-checkbox
                    dense
                    :value="subitem.value"
                    :input-value="active"
                    color="primary"
                    v-if="subitem.type === 'boolean'"
                  ></v-checkbox>
                  <!-- <v-else> другой тип данных-->
                </v-list-item-action>
              </template>
            </v-list-item>
          </template>
        </v-list-item-group>
      </v-list-group>
    </v-list>
  </v-container>
</template>

<script>
import { copyObject, isEmptyObject } from '@/lib/objects'
export default {
  name: 'UserAttributes',
  data: () => ({
    attributesList: {},
    subFormState: {},
    selected: {},
    isEmptyObject,
    selectedGr: 1,
  }),
  model: {
    //Current User Attributes
    prop: 'userAttributes',
    event: 'change',
  },

  props: {
    availableAttributes: {
      type: Object,
      default: () => {},
      required: true,
    },
    userAttributes: {
      type: Object,
      default: () => {},
      required: false,
    },
  },

  mounted() {
    this.init()
  },

  methods: {
    init() {
      const items = []
      const availablesAttrs = copyObject(this.availableAttributes)
      for (const field in availablesAttrs) {
        let title = ''
        const arr = []
        for (const subField in availablesAttrs[field]) {
          if (subField === 'title') {
            title = availablesAttrs[field][subField]
            continue
          }
          const item = availablesAttrs[field][subField]
          // заполнение по факту из входящей модели
          if (
            item.type === 'boolean' &&
            this.userAttributes &&
            // eslint-disable-next-line no-prototype-builtins
            this.userAttributes.hasOwnProperty(field) &&
            // eslint-disable-next-line no-prototype-builtins
            this.userAttributes[field].hasOwnProperty(subField)
          ) {
            item.value = this.userAttributes[field][subField]
          }
          item.field = subField
          arr.push(item)
        }
        const model = arr.filter(el => el.value).map(el => el.field)
        items.push({ field, title, items: arr, model })
      }

      this.selected = items
    },

    doChange() {
      const state = {}
      this.selected.forEach(item => {
        if (item?.model && item.model?.length) {
          state[item.field] = {}
          item.model.forEach(subField => {
            state[item.field][subField] = true
          })
        }
      })

      this.$emit('change', state)
    },
  },
}
</script>

<style>
.no-active.v-list-item--active::before {
  opacity: 0;
}
.v-list-item__action {
  align-self: center;
  margin: 8px 0;
}
</style>

import api from '@/lib/axiosApi'

export class EApiUsersException extends Error {
  constructor(message) {
    super(`[Users] ${message}`)
    this.name = 'EApiUsersException'
  }
  static check(user) {
    if (!user) {
      throw new this('Данные о Пользователе не могут быть пустыми')
    }
  }
  static checkId(val) {
    if ((typeof val === 'object' && !val?.id) || !val) {
      throw new this('Пользователь не определён (отсутствует id)')
    }
  }
}

export const users = {
  getUsers: () => {
    return api.get('users')
  },

  getUser: id => {
    EApiUsersException.checkId(id)
    return api.get(`users/${id}`)
  },

  createUser: user => {
    EApiUsersException.check(user)
    return api.post('users', user)
  },

  updateUser: user => {
    EApiUsersException.check(user)
    EApiUsersException.checkId(user)
    return api.patch(`users/${user.id}`, user)
  },

  deleteUser: id => {
    EApiUsersException.checkId(id)
    return api.delete(`users/${id}`)
  },

  getUsersAtributes: () => {
    return api.get('users/attributes')
  },

  getRoles: () => {
    return api.get('roles')
  },

  checkEmail: async email => {
    const data = await api.post('users/check-email', { email })
    return !data?.email
  },
}

export default users

<template>
  <div id="user-pages">
    <PageTitle title="Пользователи" icon />
    <UsersList />
  </div>
</template>

<script>
import PageTitle from '@/components/common/PageTitle.vue'
import UsersList from '../entity/list/UsersList.vue'
import usersModule from '../store'

export default {
  name: 'ProjectsPage',
  components: { UsersList, PageTitle },
  beforeCreate() {
    usersModule.register()
  },
  destroyed() {
    usersModule.unregister()
  },
}
</script>

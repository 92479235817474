<template>
  <v-container id="user-page" fluid>
    <v-row class="pt-3">
      <v-col></v-col>
      <v-col sm="6" md="4" class="text-right mb-2">
        <v-text-field
          v-model="search"
          prepend-icon="mdi-magnify"
          :label="searchLabel"
          hide-details
          clearable
          class="ma-0 pa-0 mr-6"
          style="max-width: 480px"
        ></v-text-field>
      </v-col>
    </v-row>

    <ReferenceTip
      class="py-5 mt-2"
      v-if="URN"
      label="Клик по строке открывает редактирование и удаление. Клик по заголовку столбца сортирует таблицу"
    />

    <v-data-table
      :items="users"
      :headers="headers"
      :search="search"
      hide-default-footer
      disable-pagination
      item-key="id"
      no-results-text="Никого не нашли"
      @click:row="openForEdit"
      :loading="!usersList"
      @pagination="pagination"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-spacer></v-spacer>
          <span v-if="isAdmin">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  small
                  outlined
                  class="mb-1 mx-2"
                  color="blue-grey"
                  @click="openForAdd"
                >
                  <v-icon color="primary darken-4" small left>
                    mdi-plus
                  </v-icon>
                  Добавить
                </v-btn>
              </template>
              <span> Добавить пользователя </span>
            </v-tooltip>
          </span>
        </v-toolbar>
      </template>

      <template v-slot:[`item.index`]="{ index }">
        <span class="cursor-pointer">{{ pageStart + index + 1 }}.</span>
      </template>

      <template v-slot:[`item.fio`]="{ value }">
        <span
          :title="value"
          :inner-html.prop="value | shortFIO | highlight(search)"
        />
      </template>

      <template v-slot:[`item.role`]="{ item }">
        <span :inner-html.prop="item.role.title | highlight(search)"></span>
      </template>

      <template v-slot:[`item.email`]="{ value }">
        <span :inner-html.prop="value | highlight(search)" />
      </template>
    </v-data-table>

    <v-dialog v-if="dialog" v-model="dialog" width="700px" persistent>
      <UserForm
        :id="item?.id"
        :availableAttributes="usersAttributes"
        @close-dialog="closeDialog"
      />
      <!-- :item="item" -->
    </v-dialog>
  </v-container>
</template>

<script>
import ReferenceTip from '@/components/ui/tips/ReferenceTip.vue'
import UserForm from '../forms/UserForm.vue'
import { mapGetters } from 'vuex'
import usersModule from '../../store'

export default {
  components: {
    ReferenceTip,
    UserForm,
  },
  data() {
    return {
      userRoleName: '',
      dialog: false,
      item: null,
      showAllUsers: true,
      search: '',
      headers: [],
      pageStart: 0,
    }
  },
  computed: {
    ...mapGetters(['isAdmin']),
    ...usersModule.mapGetters(['usersList', 'usersAttributes']),
    URN() {
      return this.isAdmin
    },
    users() {
      let result = [...this.usersList]

      return result
    },
    searchLabel() {
      return 'Поиск'
    },
  },
  created() {
    this.headers = this.makeHeaders()
  },
  mounted() {
    this.GET_USERS()
    this.GET_USERS_ATTRIBUTES()
  },

  beforeDestroy() {
    this.CLEAR_STORED_USERS()
  },

  methods: {
    ...usersModule.mapActions([
      'GET_USERS',
      'GET_USERS_ATTRIBUTES',
      'CLEAR_STORED_USERS',
    ]),
    makeHeaders() {
      const head = []
      head.push({
        text: '№',
        value: 'index',
        width: '5px',
        align: 'center',
        sortable: false,
        cellClass: 'text--disabled pl-2 pr-2',
        class: 'pl-2 pr-2',
      })
      head.push({
        text: 'ФИО',
        value: 'fio',
        width: '25%',
        cellClass: 'font-weight-medium',
      })
      head.push({ text: 'Email', value: 'email' })
      head.push({
        text: 'Роль',
        value: 'role',
        width: '20%',
        cellClass: 'text--secondary',
      })

      return head
    },
    openForEdit(item) {
      if (!this.URN) return
      this.item = item
      this.dialog = true
    },
    openForAdd() {
      this.item = null
      this.dialog = true
    },
    closeDialog() {
      this.dialog = false
      this.item = null
    },
    pagination({ pageStart }) {
      this.pageStart = pageStart
    },
  },
}
</script>

<style scoped>
.v-data-table >>> td,
.pointer {
  cursor: pointer;
}
.v-dialog__content {
  align-items: flex-start;
  padding-top: 15vh;
}
.filter-btn {
  cursor: pointer;
  align-self: end;
  position: relative;
  top: 4px;
}
.pl-2px {
  padding-left: 2px !important;
}
.vert-container {
  position: relative;
  min-height: 50px;
}
.vert-btn {
  position: absolute;
  bottom: 16px;
  left: 16px;
}
</style>

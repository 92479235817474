import { createNamespacedHelpers } from 'vuex'
import { registerModule, unregisterModule } from '@/store'
import { usersModule } from './const'

import users from './users'

export default {
  name: usersModule,
  register: () => registerModule(usersModule, { ...users, namespaced: true }),
  unregister: () => unregisterModule(usersModule),
  // map store
  ...createNamespacedHelpers(usersModule),
}

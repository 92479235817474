import Vue from 'vue'
import api from '../api'
import { isEmptyObject } from '@/lib/objects'

const state = {
  user: null,
  users: null,
  userRoles: null, //список пользователей системы
  usersAttributes: {},
}

const prod = process.env.NODE_ENV === 'production'

const getters = {
  storedUser: state => state.user || {},
  usersList: state => state.users ?? [],
  usersAttributes: state => state.usersAttributes ?? {},
  userRoles: state => state.userRoles,
}

const mutations = {
  set_users(state, users) {
    state.users = (users ?? []).filter(({ id }) => !prod || id > 1)
  },
  set_user(state, user) {
    state.user = user
  },
  save_user(state, user) {
    const index = state.users.findIndex(obj => obj.id === user.id)
    Vue.set(state.users, index, user)
  },
  delete_user(state, id) {
    const index = state.users.findIndex(obj => obj.id === id)
    state.users.splice(index, 1)
  },
  add_user(state, user) {
    state.users.push(user)
  },
  set_default_users(state) {
    state.users = []
  },
  set_default_user(state) {
    state.user = {}
  },
  set_users_attributes(state, attributes) {
    state.usersAttributes = attributes
  },
  set_default_attributes(state) {
    state.usersAttributes = {}
  },
  SET_ROLES(state, roles) {
    state.userRoles = roles
  },
}

const actions = {
  /** получаем доступные роли пользователей */
  async GET_ROLES({ getters, commit }) {
    if (getters.userRoles) return
    try {
      const data = await api.getRoles()
      commit('SET_ROLES', data)
    } catch (err) {
      commit('SET_ROLES', null)
      commit(
        'SET_ERROR',
        {
          head: 'GET_ROLES',
          text: 'Ошибка загрузки справочника ролей пользователей',
          err,
        },
        { root: true }
      )
      throw err
    }
  },
  async GET_USERS({ getters, commit, rootGetters }, force) {
    // только для админа
    if (!rootGetters.isAdmin) {
      console.warn('GET_USERS - only for Admin')
      return
    }
    // второй раз не грузимся - только F5
    if (!force && getters.usersList.length) return

    try {
      const data = await api.getUsers()
      commit('set_users', data)
    } catch (error) {
      commit(
        'SET_ERROR',
        {
          head: 'Пользователи',
          text: 'Ошибка получения пользователей',
          error,
        },
        { root: true }
      )
    }
  },
  async GET_USER({ commit }, id, force) {
    if (!force && getters.user?.id === id) {
      return
    }
    try {
      commit('set_default_user')
      const data = await api.getUser(id)

      commit('set_user', data || {})
    } catch (error) {
      commit(
        'SET_ERROR',
        {
          head: 'Пользователь',
          text: `Ошибка получения пользователя с id: ${id}`,
          error,
        },
        { root: true }
      )
    }
  },
  async ADD_USER({ commit, dispatch }, user) {
    try {
      const data = await api.createUser(user)
      commit('add_user', data)
      return data
    } catch (error) {
      commit(
        'SET_ERROR',
        {
          head: 'Пользователи',
          text: 'Ошибка добавления пользователя',
          error,
        },
        { root: true }
      )
      dispatch('GET_USERS')
      throw new Error('Ошибка добавления пользователя')
    }
  },
  async DELETE_USER({ commit, dispatch }, id) {
    try {
      await api.deleteUser(id)
      commit('delete_user', id)
    } catch (error) {
      commit(
        'SET_ERROR',
        {
          head: 'Пользователи',
          text: 'Ошибка удаления пользователя',
          error,
        },
        { root: true }
      )
      dispatch('GET_USERS')
      throw new Error('Ошибка удаления пользователя')
    }
  },
  async SAVE_USER({ commit, dispatch }, user) {
    try {
      const data = await api.updateUser(user)
      commit('save_user', data)
      return data
    } catch (error) {
      commit(
        'SET_ERROR',
        {
          head: 'Пользователи',
          text: 'Ошибка сохранения пользователя',
          error,
        },
        { root: true }
      )
      dispatch('GET_USERS')
      throw new Error('Ошибка сохранения пользователя')
    }
  },
  async GET_USERS_ATTRIBUTES({ getters, commit, rootGetters }, force) {
    // только для админа
    if (!rootGetters.isAdmin) {
      console.warn('GET_USERS_ATTRIBUTES - only for Admin')
      return
    }
    if (!force && !isEmptyObject(getters.usersAttributes)) return

    try {
      const data = await api.getUsersAtributes()
      commit('set_users_attributes', data)
    } catch (error) {
      commit(
        'SET_ERROR',
        {
          head: 'Пользователи',
          text: 'Ошибка получения возможных атрибутов пользователей',
          error,
        },
        { root: true }
      )
    }
  },
  CLEAR_STORED_USERS({ commit }) {
    commit('set_default_users')
    commit('set_default_attributes')
  },
  CLEAR_STORED_USER({ commit }) {
    commit('set_default_user')
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}

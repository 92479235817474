<template>
  <v-card v-if="!showResetPasswordDialog" class="pa-2">
    <!-- <v-container> -->
    <!-- <div class="title">{{ actionTitle }} пользователя</div> -->
    <v-toolbar dense flat>
      <v-icon class="mr-2">mdi-account</v-icon>
      <v-toolbar-title class="title">
        {{ actionTitle }} пользователя
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon small @click="closeDialog">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-container class="pt-0">
      <v-form @submit.prevent="submitHandler" v-if="!isEmptyObject(formData)">
        <v-row>
          <v-col>
            <v-tabs height="32" v-model="tab">
              <v-tab href="#main">Общие</v-tab>
              <v-tab href="#attributes" :disabled="isDisabledTabAttributes"
                >Атрибуты</v-tab
              >
              <v-tab-item class="pt-4" value="main">
                <v-container class="pa-0">
                  <v-row no-gutters>
                    <v-col>
                      <v-text-field
                        label="ФИО"
                        v-model="formData.fio"
                        required
                        :error-messages="fioErrors"
                        :hint="formData.fio | shortFIO"
                        persistent-hint
                        class="mb-2"
                        :loading="loading"
                      ></v-text-field>
                      <v-select
                        v-model="formData.role.id"
                        :items="userRoles"
                        label="Роль"
                        item-text="title"
                        item-value="id"
                        :loading="loading"
                      ></v-select>
                      <v-text-field
                        label="Email"
                        required
                        v-model.trim="$v.formData.email.$model"
                        :error-messages="emailErrors"
                        @input="$v.formData.email.$touch()"
                        @blur="$v.formData.email.$touch()"
                        :loading="loading"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-tab-item>
              <v-tab-item class="py-4" value="attributes">
                <UserAttributes
                  :availableAttributes="availableAttributes"
                  v-model="formData.attributes"
                />
              </v-tab-item>
            </v-tabs>
          </v-col>
        </v-row>
        <v-row v-if="!showConfirmations" class="pt-2">
          <v-col md="12">
            <v-btn
              v-if="isOpenForEdit && isMainTab"
              color="error"
              class="mr-4"
              @click="showDeleteUser"
              small
              text
              >Удалить</v-btn
            >
            <v-btn
              v-if="isOpenForEdit && isMainTab"
              color="error"
              class="mr-4"
              @click="showResetPassword"
              small
              text
              >Сбросить пароль</v-btn
            >
            <v-btn @click="closeDialog" class="mr-3 float-right" small text
              >Отменить</v-btn
            >
            <v-btn
              type="submit"
              color="primary"
              class="mr-4 float-right"
              small
              text
              :loading="saveUserInProgress"
              :disabled="saveUserInProgress"
              >Сохранить</v-btn
            >
          </v-col>
          <!-- <v-btn v-if="isManager" right icon @click="showActionHistory = true"
            ><v-icon>mdi-history</v-icon></v-btn
          > -->
          <!-- <v-dialog
            width="1000px"
            v-model="showActionHistory"
            v-if="showActionHistory"
          >
            <v-card>
              <user-actions forUser :search-log="{ user_id: item.id }" />
            </v-card>
          </v-dialog> -->
        </v-row>

        <v-row v-if="showDeleteUserConfirmation" class="mb-4">
          <v-col> Внимание! Вы точно хотите удалить этого пользователя? </v-col>
        </v-row>
        <v-row v-if="showDeleteUserConfirmation">
          <v-col>
            <v-btn @click="hideConfirmations" class="mr-4" small text
              >Отменить</v-btn
            >
            <v-btn
              color="error"
              class="mr-4"
              @click="deleteUser(formData.id)"
              small
              text
              :loading="deleteUserInProgress"
              :disabled="deleteUserInProgress"
              >Удалить</v-btn
            >
          </v-col>
        </v-row>

        <v-row v-if="showResetPasswordConfirmation" class="mb-4">
          <v-col>
            Внимание! Вы точно хотите сменить этому пользователю пароль? Нажмите
            Нажмите "Сбросить пароль" и следуйте инструкциям
          </v-col>
        </v-row>
        <v-row v-if="showResetPasswordConfirmation">
          <v-col>
            <v-btn @click="hideConfirmations" class="mr-4" small text
              >Отменить</v-btn
            >
            <v-btn
              color="error"
              class="mr-4"
              @click="resetPassword(formData.id)"
              small
              text
              :loading="resetPasswordInProgress"
              :disabled="resetPasswordInProgress"
              >Сбросить пароль</v-btn
            >
          </v-col>
        </v-row>

        <!--      Вот тут показываем ещё одну строку с ошибкой, если она есть-->
        <v-row v-if="error_message">
          <v-alert outlined type="error" class="my-3">
            {{ error_message }}
          </v-alert>
        </v-row>
      </v-form>
    </v-container>
  </v-card>
  <v-card v-else-if="showResetPasswordDialog" flat>
    <CopyToClipboard :value="link" :user="newUser" @close="closeDialog" />
  </v-card>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
import { required, email } from 'vuelidate/lib/validators'
import api from '../../api'
import { ROUTE_404 } from '@/router/const'
import CopyToClipboard from '@/components/ui/buttons/CopyToClipboard'
import { copyObject, isEmptyObject } from '@/lib/objects'
import usersModule from '../../store'
import UserAttributes from './components/UserAttributes.vue'
import { DEFAULT_ROLE_ID } from '../../config/userParamsDefault'

const TEMPLATE_FORM_DATA = {
  id: null,
  fio: '',
  role_id: -1,
  role: {
    id: -1,
  },
  email: '',
  attributes: {},
}

export default {
  name: 'UserForm',
  components: { /* UserActions ,*/ CopyToClipboard, UserAttributes },
  props: {
    id: { type: [Number, null], require: true },
    availableAttributes: { type: Object, require: true },
  },
  validations: {
    formData: {
      fio: { required },
      email: {
        required,
        email,
        async isUnique(value) {
          const eq = this.oldMail === value
          if (!this.isOpenForAdd && eq) return true
          if (!value) return
          if (!this.validateEmail(value)) return
          return await api.checkEmail(value)
        },
      },
    },
  },
  data() {
    return {
      loading: false,
      formData: {},

      oldMail: '',
      token: '',
      newUser: null, // новый пользователь после создания

      error_message: '', // единая переменная ошибок для этого компонента

      showDeleteUserConfirmation: false, // показать предупреждение удаления юзера
      showResetPasswordConfirmation: false, // показать предупреждение смены пароля юзера
      showResetPasswordDialog: false, // показать диалоговое окно со сменой пароля

      // три переменные для блокировки соответствующих кнопок на время запроса
      deleteUserInProgress: false,
      saveUserInProgress: false,
      resetPasswordInProgress: false,

      // showActionHistory: false,
      tab: 'main',
      DEFAULT_ROLE_ID,
      isEmptyObject,
    }
  },
  watch: {
    id: {
      immediate: true,
      handler(val) {
        this.initFormData()
        if (this.isOpenForAdd) {
          return
        }
        this.getUser(val)
      },
    },
  },
  async created() {
    if (!this.id) this.initFormData()
    await this.GET_ROLES()
  },
  beforeDestroy() {
    this.CLEAR_STORED_USER()
  },
  computed: {
    ...usersModule.mapGetters(['userRoles', 'storedUser']),

    emailErrors() {
      const errors = []
      if (!this.$v.formData.email.$dirty) return errors
      !this.$v.formData.email.required &&
        errors.push('Это поле не должно быть пустым')
      !this.$v.formData.email.email && errors.push('Введите почту')
      !this.$v.formData.email.isUnique &&
        errors.push('Такой адрес уже используется')

      return errors
    },
    fioErrors() {
      const errors = []
      if (!this.$v.formData.fio.$dirty) return errors
      !this.$v.formData.fio.required &&
        errors.push('Это поле не должно быть пустым')
      return errors
    },
    isOpenForEdit() {
      return Boolean(this.id)
    },
    isOpenForAdd() {
      return !this.isOpenForEdit
    },
    actionTitle() {
      return this.isOpenForEdit ? 'Редактировать' : 'Добавить'
    },
    link() {
      return `${window.location.origin}/reset-password/${this.token}`
    },
    showConfirmations() {
      return (
        this.showResetPasswordConfirmation || this.showDeleteUserConfirmation
      )
    },
    isDisabledTabAttributes() {
      return (
        this.saveUserInProgress ||
        this.emailErrors?.length !== 0 ||
        this.fioErrors?.length !== 0 ||
        this.showResetPasswordConfirmation ||
        this.showDeleteUserConfirmation
      )
    },
    isMainTab() {
      return this.tab === 'main'
    },
  },
  methods: {
    ...mapActions(['generateResetPasswordToken']),
    ...usersModule.mapActions([
      'GET_USER',
      'SAVE_USER',
      'GET_ROLES',
      'ADD_USER',
      'DELETE_USER',
      'CLEAR_STORED_USER',
    ]),
    ...mapMutations(['set_current_role']),
    async getUser(id) {
      if (id) {
        if (isNaN(+id)) {
          this.$router.push({ name: ROUTE_404 }).catch(() => null)
        }
        this.loading = true
        this.error_message = ''
        try {
          await this.GET_USER(id)
          this.formData = copyObject(this.storedUser)
          this.oldMail = this.storedUser?.email
          this.$v.$touch()
        } catch (err) {
          this.error_message = err
        }
      }
      this.loading = false
    },
    initFormData() {
      this.formData = copyObject(TEMPLATE_FORM_DATA)
      this.formData.role.id = this.DEFAULT_ROLE_ID
    },
    submitHandler() {
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }
      this.saveUser()
    },
    closeDialog() {
      this.hideConfirmations()
      this.$emit('close-dialog')
      this.formData = null
    },
    showDeleteUser() {
      this.showDeleteUserConfirmation = true
    },
    showResetPassword() {
      this.showResetPasswordConfirmation = true
    },
    hideConfirmations() {
      this.error_message = ''
      this.showDeleteUserConfirmation = false
      this.showResetPasswordConfirmation = false
    },

    async deleteUser(id = null) {
      this.deleteUserInProgress = true
      this.error_message = ''
      try {
        await this.DELETE_USER(id)
        this.$toast.success('Успешно удален')
        this.closeDialog()
      } catch (err) {
        this.error_message = err
      } finally {
        this.deleteUserInProgress = false
      }
    },

    async saveUser() {
      this.error_message = ''
      this.saveUserInProgress = true
      try {
        this.formData.role_id = this.formData.role.id

        if (this.isOpenForAdd) {
          // если создать пользователя с пустым паролем, ему автоматом будет выдан токен для сброса
          this.formData.password = null

          this.newUser = await this.ADD_USER(this.formData)
          this.$toast.success('Пользователь добавлен')
          // добавили нового юзера и сразу открыли вкладку токен сброса пароля
          this.token = this.newUser.token_reset_password
          this.showResetPasswordDialog = true
        } else {
          await this.SAVE_USER(this.formData)
          this.set_current_role(this.formData)
          this.$toast.success('Сохранено.')
          this.closeDialog()
        }
      } catch (err) {
        this.error_message = err
      } finally {
        this.saveUserInProgress = false
      }
    },

    async resetPassword(userId) {
      this.resetPasswordInProgress = true
      this.error_message = ''
      try {
        this.newUser = await this.generateResetPasswordToken(userId)
        this.token = this.newUser.token_reset_password
        this.showResetPasswordDialog = true
      } catch (err) {
        this.error_message = err
      } finally {
        this.resetPasswordInProgress = false
      }
    },

    validateEmail(v) {
      return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v)
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .v-toolbar__content {
  padding-left: 10px !important;
  padding-right: 18px !important;
}
</style>
